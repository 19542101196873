// https://chakra-ui.com/docs/styled-system/customize-theme#customizing-single-components
export const components = {
  Button: {
    baseStyle: {
      backgroundColor: 'brand.yellow',
      color: 'brand.blue'
    },
    variants: {
      base: {}
    },
    defaultProps: {
      variant: 'base'
    }
  }
}
