import { WebStorageStateStore } from 'oidc-client-ts'
import type { AuthProviderProps } from 'react-oidc-context'
import env from './env'

const oidcConfig: AuthProviderProps = {
  authority: env.OIDC_REALM_URL,
  client_id: env.OIDC_CLIENT_ID,
  redirect_uri: env.OIDC_REDIRECT_URI,
  post_logout_redirect_uri: env.OIDC_LOGOUT_REDIRECT_URI,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: user => {
    window.history.replaceState({}, document.title, window.location.pathname)
  }
}

export default oidcConfig
