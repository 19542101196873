// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/index.ts
import { extendTheme } from '@chakra-ui/react'
import { styles } from './styles'
import { fonts } from './fonts'
import { colors } from './colors'
import { components } from './components'
import '@fontsource/roboto'

// 2. Define the new text styles
export const theme = extendTheme({
  styles,
  fonts,
  colors,
  components
})
