import { Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from './Header'
import { Menu } from './Menu'

export const Layout: React.FC = () => {
  return (
    <Grid
      templateAreas={`'header header'
                  'nav main'
                  'footer footer'`}
      gridTemplateRows={'55px 1fr 30px'}
      gridTemplateColumns={'150px 1fr'}
      h='100vh'
      fontWeight='bold'
    >
      <GridItem p='2' area={'header'} bg='brand.blue' position={'fixed'} width={'100%'}>
        <Header />
      </GridItem>
      <GridItem pl='2' area={'nav'} bg='brand.blue' color='brand.white'>
        <Menu />
      </GridItem>
      <GridItem pl='2' area={'main'}>
        <Outlet />
      </GridItem>
      <GridItem pl='2' area={'footer'} bg='brand.blue' color='brand.yellow'>
        Footer
      </GridItem>
    </Grid>)
}
