import { VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

export const Menu: React.FC = () => {
  return (
    <VStack alignItems='self-start' gap='5' position={'fixed'}>
      <Link to={'/'}>Home</Link>
      <Link to={'/apps'}>Applications</Link>
    </VStack>
  )
}
