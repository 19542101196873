import { Box, Button, ButtonGroup, Flex, Heading, Spacer, Spinner } from '@chakra-ui/react'
import React from 'react'
import { useAuth } from 'react-oidc-context'

export const Header: React.FC = () => {
  const auth = useAuth()

  const handleLogin = (): void => {
    void auth.signinRedirect()
  }

  const handleLogout = (): void => {
    void auth.removeUser()
    void auth.signoutRedirect()
  }

  const AuthButton: React.FC = () => {
    if (auth.isLoading) {
      return <Spinner />
    }

    if (auth.error !== undefined) {
      throw auth.error
    }

    if (!auth.isAuthenticated) {
      return <Button onClick={handleLogin}>Log in</Button>
    }

    return <Button onClick={handleLogout}>Log out</Button>
  }

  return (
    <Flex pr={5} minWidth='max-content'>
      <Box p='2'>
        <Heading size='md' color='brand.yellow'>RicardoVZ</Heading>
      </Box>
      <Spacer />
      <ButtonGroup gap='2'>
        <AuthButton />
      </ButtonGroup>
    </Flex>
  )
}
