
const getEnvOrDefault = (envNameSuffix: string): string => {
  return process.env[`REACT_APP_${envNameSuffix}`] ?? `MISSING_ENV_REACT_APP_${envNameSuffix}`
}

const env = {
  OIDC_REALM_URL: getEnvOrDefault('OIDC_REALM_URL'),
  OIDC_CLIENT_ID: getEnvOrDefault('OIDC_CLIENT_ID'),
  OIDC_REDIRECT_URI: getEnvOrDefault('OIDC_REDIRECT_URI'),
  OIDC_LOGOUT_REDIRECT_URI: getEnvOrDefault('OIDC_LOGOUT_REDIRECT_URI'),
  HOMELAB_API_URL: getEnvOrDefault('HOMELAB_API_URL')
}
export default env
