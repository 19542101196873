import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Container } from '@chakra-ui/react'
import * as React from 'react'
import { Link, useRouteError } from 'react-router-dom'

export const ErrorPage: React.FC = () => {
  const error = useRouteError()
  let errorMessage = ''
  console.error(error)
  if (typeof error === 'object' && error !== null) {
    if ('statusText' in error) {
      errorMessage = error.statusText as string
    } else if ('message' in error) {
      errorMessage = error.message as string
    }
  }

  return (
    <Container>
      <Alert
        status='error'
        variant='subtle'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        height='200px'
      >
        <AlertIcon boxSize='60px' mr={0} />
        <AlertTitle mt={4} mb={1} fontSize='lg'>
          {errorMessage}
        </AlertTitle>
        <AlertDescription maxWidth='sm'>
          Sorry, an unexpected error has occurred.
        </AlertDescription>
        <Button colorScheme='teal'>
          <Link to={'/'}>Back to home</Link>
        </Button>
      </Alert>
    </Container>
  )
}
