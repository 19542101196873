import { Card, CardBody, Container, Heading, Image, Link, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import env from '../env'

interface App {
  'id': string
  'name': string
  'description': string
  'url': string
  'iconUrl': string
}

export const Apps: React.FC = () => {
  const [apps, setApps] = useState<App[]>([])
  const auth = useAuth()
  const token = auth.user?.access_token
  useEffect(() => {
    if (token === undefined) {
      return
    }
    axios(`${env.HOMELAB_API_URL}/apps/list`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => { setApps(response.data) })
      .catch(error => {
        console.error(error)
        return []
      })
  }, [token])

  return (
    <Container maxW={'1000px'} p={50}>
      <Heading as='h1' size='lg'>Homelab Applications</Heading>
      <Wrap>
        {apps.map(app =>
          <WrapItem key={app.id}>
            <Link href={app.url} target='_blank'>
              <Card width={250} height={250}>
                <CardBody>
                  <VStack>
                    <Image
                      src={app.iconUrl}
                      alt={app.name} height={100} width={100}
                    />
                    <Heading size='md'>{app.name}</Heading>
                    <Text>{app.description}</Text>
                  </VStack>
                </CardBody>
              </Card>
            </Link>
          </WrapItem>
        )
        }
      </Wrap>
    </Container >
  )
}
