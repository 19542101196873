import React from 'react'
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { ErrorPage } from './view/ErrorPage'
import { Apps } from './view/Apps'
import { Layout } from './view/layout/Layout'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path='/'
      element={<Layout />}
      errorElement={<ErrorPage />}
    >
      <Route errorElement={<ErrorPage />} >
        <Route
          path='/'
          element={<Apps />}
        />
        <Route
          path='apps'
          element={<Apps />}
        />
      </Route>
    </Route >
  )
)
