import type { Styles } from '@chakra-ui/theme-tools'

export const styles: Styles = {
  global: {
    body: {
      fontFamily: 'body',
      color: 'brand.blue',
      bg: 'brand.white'
    }
  }
}
